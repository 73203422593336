<template>
    <v-container fluid>
        <v-toolbar flat>
            <v-toolbar-title
                ><colored-status :value="jobStatus.active" :tooltip="jobStatus.tooltip" />{{
                    $t('oamjobad/label--overview-title')
                }}</v-toolbar-title
            >
            <v-divider class="mx-4" inset vertical />
            <v-spacer />
            <v-btn color="primary" class="mb-2" @click="add()">
                {{ $t('oamjobad/action--new-job-ad') }}
            </v-btn>
        </v-toolbar>
        <v-card outlined>
            <v-data-table
                :headers="headers"
                :items="ads"
                item-key="jobAdId"
                :loading="loading"
                group-by="jobAdId"
                dense
                outlined
                hide-default-footer
                disable-pagination
                :loading-text="$t('oamcommon/component--loading')"
                :no-data-text="$t('oamjobad/message--no-data')"
            >
                <template #[`group.header`]="{ items, toggle, isOpen }">
                    <th class="text-truncate" style="height: 50px; max-width: 450px" @dblclick="edit(items[0])">
                        <v-btn :ref="items" small icon :data-open="isOpen" @click="toggle" @dblclick.stop="">
                            <v-icon v-if="isOpen"> mdi-chevron-up </v-icon>
                            <v-icon v-else> mdi-chevron-down </v-icon>
                        </v-btn>
                        <colored-status :value="items[0].adStatusActive" :tooltip="items[0].adStatusText" />{{
                            items[0].title
                        }}
                    </th>
                    <th @dblclick="edit(items[0])">
                        {{ items[0].language }}
                    </th>
                    <th @dblclick="edit(items[0])">
                        {{ items[0].publishedAt | datePresenter }}
                    </th>
                    <th class="d-none d-md-table-cell" colspan="3" @dblclick="edit(items[0])" />
                    <th class="d-none d-lg-table-cell" colspan="3" @dblclick="edit(items[0])" />
                    <th>
                        <v-tooltip bottom>
                            <template #activator="{ on }">
                                <v-icon dense class="mr-2" @click="deactivate(items[0])" v-on="on">
                                    mdi-close-box-multiple-outline
                                </v-icon>
                            </template>
                            {{ $t('oamjobad/tooltip--deactivate-all-pubs') }}
                        </v-tooltip>
                    </th>
                    <th>
                        <v-layout>
                            <v-spacer />
                            <v-tooltip v-if="items[0].previewLink" bottom>
                                <template #activator="{ on }">
                                    <v-icon dense class="mr-2" @click="openLink(items[0].previewLink)" v-on="on">
                                        mdi-eye
                                    </v-icon>
                                </template>
                                {{ $t('oamcommon/tooltip--preview') }}
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon dense class="mr-2" @click="edit(items[0])" v-on="on"> mdi-pencil </v-icon>
                                </template>
                                {{ $t('oamcommon/tooltip--edit') }}
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template #activator="{ on }">
                                    <v-icon dense @click="del(items[0])" v-on="on"> mdi-delete </v-icon>
                                </template>
                                {{ $t('oamcommon/tooltip--delete') }}
                            </v-tooltip>
                        </v-layout>
                    </th>
                </template>

                <template #item="{ item }">
                    <tr v-if="item.id">
                        <td colspan="3" />

                        <td class="d-none d-md-table-cell text-truncate" style="max-width: 100px">
                            <colored-status :value="item.pubStatusActive" :tooltip="item.pubStatusText" />{{
                                item.publicationPlace
                            }}
                        </td>
                        <td class="d-none d-md-table-cell">
                            <span :class="getGreaterThanColor(item.publishedFrom)">
                                {{ item.publishedFrom | datePresenter }}</span
                            >
                        </td>
                        <td class="d-none d-md-table-cell">
                            <span :class="getLessThanColor(item.publishedTo)">
                                {{ item.publishedTo | datePresenter }}</span
                            >
                        </td>
                        <td class="d-none d-lg-table-cell">
                            {{ item.publicationApplyCount }}
                        </td>
                        <td class="d-none d-lg-table-cell">
                            {{ item.publicationFormCount }}
                        </td>
                        <td class="d-none d-lg-table-cell">
                            {{ item.publicationReadCount }}
                        </td>
                        <td>
                            <v-switch v-model="item.isPublicationActive" dense @change="change(item.id)"> </v-switch>
                        </td>
                        <td></td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import { router } from '@/router'
import { mapFields, mapMultiRowFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
import ColoredStatus from '../../components/ColoredStatus'

export default {
    components: {
        ColoredStatus,
    },
    data: () => ({
        loading: true,
    }),
    computed: {
        ...mapFields('ad', ['jobStatus']),
        ...mapMultiRowFields('ad', ['ads']),
        headers() {
            return [
                {
                    text: this.$t('oamjobad/header--title'),
                    value: 'title',
                    sortable: false,
                },
                {
                    text: this.$t('oamjobad/header--language'),
                    value: 'language',
                    sortable: false,
                },
                {
                    text: this.$t('oamjobad/header--published-at'),
                    value: 'publishedAt',
                    sortable: false,
                },
                {
                    text: this.$t('oamjobad/header--published-place'),
                    value: 'publicationPlace',
                    class: 'd-none d-md-table-cell',
                },
                {
                    text: this.$t('oamjobad/header--published-from'),
                    value: 'publishedFrom',
                    class: 'd-none d-md-table-cell',
                },
                {
                    text: this.$t('oamjobad/header--published-to'),
                    value: 'publishedTo',
                    class: 'd-none d-md-table-cell',
                },
                {
                    text: this.$t('oamjobad/header--published-apply'),
                    value: 'publicationApplyCount',
                    class: 'd-none d-lg-table-cell',
                    sortable: false,
                },
                {
                    text: this.$t('oamjobad/header--published-form'),
                    value: 'publicationFormCount',
                    class: 'd-none d-lg-table-cell',
                    sortable: false,
                },
                {
                    text: this.$t('oamjobad/header--published-read'),
                    value: 'publicationReadCount',
                    class: 'd-none d-lg-table-cell',
                    sortable: false,
                },
                {
                    text: this.$t('oamjobad/header--published-status'),
                    value: 'isPublicationActive',
                    sortable: false,
                },
                { text: '', value: 'actions', sortable: false, width: 120 },
            ]
        },
    },
    mounted() {
        this.getItems(this.$route.params.job).then(() => {
            this.loading = false
        })
    },
    methods: {
        ...mapActions('ad', ['getItems', 'addItem', 'checkItem', 'deleteItem', 'switchPubStatus']),
        ...mapActions('pub', {
            deactivateAll: 'deactivateAll',
            deletePub: 'deleteItem',
        }),
        ...mapActions('alert', ['showSuccess']),

        add() {
            this.addItem().then((ad) => {
                let id = ad[0].id
                if (id) {
                    router.push({ name: 'ad', params: { ad: id } })
                }
            })
        },

        edit(ad) {
            router.push({ name: 'ad', params: { ad: ad.jobAdId } })
        },

        del(ad) {
            this.checkItem(ad.jobAdId).then((check) => {
                if (check != 1) {
                    this.$confirm(this.$t('oamcommon/message--delete'), {
                        title: this.$t('oamcommon/label--warning'),
                        buttonTrueText: this.$t('oamcommon/label--yes'),
                        buttonFalseText: this.$t('oamcommon/label--no'),
                        persistent: true,
                    }).then((res) => {
                        if (res) {
                            this.deleteItem(ad.jobAdId).then(() => {
                                this.getItems(this.$route.params.job)
                            })
                        }
                    })
                }
            })
        },

        change(id) {
            this.switchPubStatus(id).then(() => {
                this.getItems(this.$route.params.job)
            })
        },

        deactivate(ad) {
            this.$confirm(this.$t('oamcommon/message--action'), {
                title: this.$t('oamcommon/label--warning'),
                buttonTrueText: this.$t('oamcommon/label--yes'),
                buttonFalseText: this.$t('oamcommon/label--no'),
                persistent: true,
            }).then((res) => {
                if (res)
                    this.deactivateAll(ad.jobAdId).then(() => {
                        this.getItems(this.$route.params.job)
                    })
            })
        },

        editPub(pub) {
            router.push({
                name: 'pub',
                params: { ad: pub.jobAdId, pub: pub.id },
            })
        },

        delPub(pub) {
            this.$confirm(this.$t('oamcommon/message--delete'), {
                title: this.$t('oamcommon/label--warning'),
                buttonTrueText: this.$t('oamcommon/label--yes'),
                buttonFalseText: this.$t('oamcommon/label--no'),
                persistent: true,
            }).then((res) => {
                if (res) {
                    this.deletePub(pub.id).then(() => {
                        this.getItems(this.$route.params.job)
                    })
                }
            })
        },

        openLink(link) {
            if (link.indexOf('//') > 0) {
                window.open(link)
            } else {
                window.open('//' + link)
            }
        },

        getLessThanColor(date) {
            return new Date(date).getTime() < new Date().getTime() ? 'warning--text' : ''
        },

        getGreaterThanColor(date) {
            return new Date(date).getTime() > new Date().getTime() ? 'warning--text' : ''
        },
    },
}
</script>

<style>
/* .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover {
    background: #e9e9e9 !important;
} */
</style>
